import { FC, useEffect, useState } from 'react';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { DatePicker } from '../../../shared/form-control/DatePicker';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

const DateAction: FC<ActionBaseProps<string>> = (props) => {
  const { response, data, required } = props;
  const { onAnswer, readOnly } = useFormAction(props);

  const [value, setValue] = useState<Date | null>(response ? new Date(response) : null);

  useEffect(() => {
    setValue(response ? new Date(response) : null);
  }, [response]);

  const onDateChange = (date: Date | null) => {
    setValue(date);
    onAnswer(date ? date.toISOString() : '');
  };

  return (
    <div className="flex flex-col" data-cy="date-action">
      <ActionTitleDescription required={required} {...data} />
      <DatePicker date={value} emptyWhenNull onChange={onDateChange} disabled={readOnly} notBefore={null} />
    </div>
  );
};

export default DateAction;
